import axios from "axios";
import {useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import ErrorCode from "../../helpers/errorcode.json";
import CryptoJS from "crypto-js";
const Login = () => {
    const [eye, setEye] = useState(false);
    const [errorUsername, setErrorUsername] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorusernameMessage, setErrorUsernameMessage] = useState('')
    const [errorPasswordmessage, setErrorPasswordmessage] = useState('')
    const inputUsername = useRef();
    const inputPassword = useRef();

    const navigate = useNavigate();

    const eyeHandle = () => {
        if (eye) {
            setEye(false);
        } else {
            setEye(true);
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault();


        let body = {
            'username': inputUsername.current.value,
            'password': inputPassword.current.value
        }

        let response = await axios.post(process.env.REACT_APP_APIHOST + '/api/auth/v01/login', body);
        if (response.data.status === 1) {
            let keyUUID = btoa('_uuid_developer');
            let valueUUID = btoa(JSON.stringify(response.data.data));
            let encryptUUID = CryptoJS.AES.encrypt(valueUUID, 'salt').toString();

            localStorage.setItem('userData', JSON.stringify(response.data.data.user));
            localStorage.setItem(keyUUID, encryptUUID);


            navigate('/userlist');
        }
        if (response.data.status === 0) {
            response.data.message.forEach(element => {
                let resultError = process.env.REACT_APP_ERRORMESSAGE ? element.message : element.code
                let replaceError = resultError.replace('###', element.code);

                switch (element.code.substring(0, 12)) {
                    case ErrorCode.unauthorize:

                        setErrorUsername(true);
                        setErrorUsernameMessage(replaceError);

                        setErrorPassword(true);
                        setErrorPasswordmessage(replaceError);
                        break;
                    default:
                        inputUsername.current.value = '';
                        inputPassword.current.value = '';

                        setErrorUsername(false);
                        setErrorPassword(false);
                        break;
                }
            })
        }
    }
    return (
        <div className="h-full w-full">
            <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/auth/loginBackground.svg'})` }} className="h-full w-full bg-no-repeat bg-cover bg-bottom px-[10%]">
                <div className="flex h-screen place-items-center">
                    <div className="flex flex-col ml-auto gap-20 items-center rounded-3xl bg-[#1E1F23] bg-opacity-70 py-[5%] px-[5%]">
                        <div>
                            <h1 className="max-sm:text-3xl md:text-4xl  text-white-primary">
                                Login
                            </h1>
                        </div>
                        <div className="flex flex-col gap-52">
                            <div className="flex flex-col gap-16">
                                <div className="max-sm:w-60 sm:w-88 md:w-96 flex flex-col items-center   py-2">
                                    <span className="self-start text-white-primary">Username</span>
                                    <div className="w-[100%] flex flex-row bg-white-primary rounded-md">
                                        <div className="flex w-[10%]" >
                                            <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/auth/user.svg'})` }}></div>
                                        </div>
                                        <input className="appearance-none bg-white-primary border-none w-[90%] text-gray-700 mr-1 py-1 px-2 leading-tight  focus:outline-none" type="text" placeholder="Username" aria-label="Username" ref={inputUsername} />
                                    </div>


                                    {errorUsername ?
                                        <div className="flex flex-row self-start">
                                            {/* <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/sign/errorSign.svg'})` }}></div> */}
                                            <span className=" text-white-primary text-center self-center"> {errorusernameMessage}</span>
                                        </div>
                                        :
                                        <div className="flex flex-row self-start">
                                            {/* <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/sign/errorSign.svg'})` }}></div> */}
                                            <span className=" text-white-primary text-center self-center">Username is required</span>
                                        </div>}
                                </div>

                                <div className="max-sm:w-60 sm:w-88 md:w-96 flex flex-col items-center border-blue-cst-border py-2">
                                    <span className="self-start  text-white-primary">Password</span>
                                    <div className="w-[100%] flex flex-row bg-white-primary rounded-md">
                                        <div className="flex w-[10%]" >
                                            <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/auth/password.svg'})` }}></div>
                                        </div>
                                        <input className="appearance-none bg-white-primary border-none w-[80%] text-gray-700  py-1 px-2 leading-tight focus:outline-none" type={eye ? "text" : "password"} placeholder="Password" aria-label="Password" ref={inputPassword} />
                                        <span className="cursor-pointer" onClick={eyeHandle}>
                                            <span className="cursor-pointer" >
                                                {eye ?
                                                    <div className=" w-[10%]" >
                                                        <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/auth/eyeClosed.svg'})` }}></div>
                                                    </div>
                                                    :
                                                    <div className="w-[10%]" >
                                                        <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/auth/eyeOpen.svg'})` }}></div>
                                                    </div>
                                                }
                                            </span>
                                        </span>
                                    </div>
                                    {errorPassword ?
                                        <div className="flex flex-row self-start">
                                            {/* <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/sign/errorSign.svg'})` }}></div> */}
                                            <span className=" text-white-primary text-center self-center"> {errorPasswordmessage}</span>
                                        </div>
                                        :
                                        <div className="flex flex-row self-start">
                                            {/* <div className="w-10 h-10 bg-no-repeat bg-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/sign/errorSign.svg'})` }}></div> */}
                                            <span className=" text-white-primary text-center self-center">Password is required</span>
                                        </div>}
                                </div>
                            </div>
                            <div className="">
                                <button className="h-12 opacity-100 max-sm:w-60 sm:w-88 md:w-96 text-lg text-white-primary transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={onSubmit}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;