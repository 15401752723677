export class Validator {
    static modifyDate = (data) => {
        var dateData = new Date(data).toLocaleDateString("ID", {
            year: "numeric",
            month: "numeric",
            day: "numeric"
        })
        var timeData = new Date(data).toLocaleTimeString("EN", {
            hour: "2-digit",
            minute: "2-digit"
        })
        return dateData + (" - ") + timeData;
    }
    static modifyBirthDate = (data) => {
        var dateData = new Date(data).toLocaleDateString("ID", {
            year: "numeric",
            month: "numeric",
            day: "numeric"
        })
       
        return dateData;
    }
}