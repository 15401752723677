import './App.css';
import Router from './router/Router';

document.title = 'CST Dashboard';

const App = () => {
	return (
		<>
			<Router />
		</>
	)
}

export default App;
