import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { positions, Provider as Providers } from "react-alert";

import AlertTemplate from 'react-alert-template-basic'
import Login from '../components/auth/Login';
import UserList from '../components/layout/container/user/UserList';
import DetailUser from '../components/layout/container/user/DetailUser';
import Logout from '../components/auth/Logout'

const options = {
    timeout: 5000,
    position: positions.TOP_RIGHT
};

const Router = () => {
    return (
        <>
            <Providers template={AlertTemplate} {...options}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Login />} />
                        <Route path='/logout' element={<Logout />} />
                        <Route path='/userlist' element={<UserList />} />
                        <Route path='/userlist/detailUser/:id' element={<DetailUser />} />
                      
                    </Routes>
                </BrowserRouter>
            </Providers>
        </>
    );
}

export default Router;