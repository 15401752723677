import { Link } from 'react-router-dom';


const Sidebar = () => {

    // //#region 
    // const isDashboardActivePage = useSelector(state => state.isDashboardActivePage);
    // const isTuhistoryActivePage = useSelector(state => state.isTuhistoryActivePage);
    // const isTfhistoryActivePage = useSelector(state => state.isTfhistoryActivePage);
    // const isTrHistoryActivePage = useSelector(state => state.isTrHistoryActivePage);

    // var backGroundDash = '';
    // var backGroundTuHist = '';
    // var backGroundTfHist = '';
    // var backGroundTrHist = '';

    // if (isDashboardActivePage) {
    //     backGroundDash = 'bg-blue-cst-primary-active';
    // }

    // if (isTuhistoryActivePage) {
    //     backGroundTuHist = 'bg-blue-cst-primary-active';
    // }

    // if (isTfhistoryActivePage) {
    //     backGroundTfHist = 'bg-blue-cst-primary-active';
    // }

    // if (isTrHistoryActivePage) {
    //     backGroundTrHist = 'bg-blue-cst-primary-active';
    // }

    //#endregion
    //    var backGroundDash = '';
    // var backGroundTuHist = '';
    // var backGroundTfHist = '';
    // var backGroundTrHist = '';

    // var backGroundDash = 'bg-blue-cst-primary-active';
    var backGroundTuHist = 'bg-blue-cst-primary-active';

    return (
        <aside className="w-16 md:w-64 fixed z-50 h-[80%] mt-5 ml-2 bg-black-toGray rounded-[32px]" aria-label="Sidebar">
            <div className="flex flex-col h-[100%] pt-7 md:pt-0 px-4 py-2 rounded-[32px] justify-between pb-10 ">
                <div className='flex flex-col'>
                    {/* <Link to={'/dashboard'} className="flex items-center mb-10 block md:hidden">
                        <img src={process.env.PUBLIC_URL + '/images/logo/CSTLogo.svg'} alt="brand" className="logo-mobile"></img>
                    </Link> */}
                    <Link to={'/userlist'} className="flex items-center mb-10 md:block">
                        <img src={process.env.PUBLIC_URL + '/images/logo/CSTLogo.svg'} alt="brand" className="logo-desktop max-sm:w-24 sm:w-30 md:w-36 ml-4 mt-4"></img>
                    </Link>
                    <ul className="space-y-2">
                        {/* <li className={backGroundDash + " rounded-[16px] hidden md:block"}>
                            <a href="/dashboard" className="flex items-center py-3 px-6 text-base font-normal text-gray-900 rounded-[16px] dark:text-white hover:bg-[#4C4DDC] dark:hover:bg-[#4C4DDC]">
                                <svg width="20" color='white' height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 13.4449H16.25V16.5699H15V13.4449ZM12.5 10.3199H13.75V16.5699H12.5V10.3199ZM6.875 16.5699C6.0465 16.5689 5.25222 16.2393 4.66639 15.6535C4.08055 15.0677 3.75099 14.2734 3.75 13.4449H5C5 13.8157 5.10997 14.1782 5.31599 14.4866C5.52202 14.7949 5.81486 15.0352 6.15747 15.1772C6.50008 15.3191 6.87708 15.3562 7.24079 15.2839C7.60451 15.2115 7.9386 15.0329 8.20083 14.7707C8.46305 14.5085 8.64163 14.1744 8.71397 13.8107C8.78632 13.447 8.74919 13.07 8.60727 12.7274C8.46536 12.3847 8.22504 12.0919 7.91669 11.8859C7.60835 11.6799 7.24584 11.5699 6.875 11.5699V10.3199C7.7038 10.3199 8.49866 10.6491 9.08471 11.2352C9.67076 11.8212 10 12.6161 10 13.4449C10 14.2737 9.67076 15.0685 9.08471 15.6546C8.49866 16.2406 7.7038 16.5699 6.875 16.5699Z" fill="#707375" />
                                    <path d="M17.5 1.56989H2.5C2.16858 1.57022 1.85083 1.70202 1.61648 1.93637C1.38213 2.17072 1.25033 2.48847 1.25 2.81989V17.8199C1.25033 18.1513 1.38213 18.4691 1.61648 18.7034C1.85083 18.9378 2.16858 19.0696 2.5 19.0699H17.5C17.8314 19.0694 18.149 18.9375 18.3833 18.7032C18.6176 18.4689 18.7495 18.1513 18.75 17.8199V2.81989C18.7497 2.48847 18.6179 2.17072 18.3835 1.93637C18.1492 1.70202 17.8314 1.57022 17.5 1.56989ZM17.5 7.19489H8.75V2.81989H17.5V7.19489ZM7.5 2.81989V7.19489H2.5V2.81989H7.5ZM2.5 17.8199V8.44489H17.5L17.5013 17.8199H2.5Z" fill="#707375" />
                                </svg>

                                <span className="ml-3">Dashboard</span>
                            </a>
                        </li> */}
                        <li className={backGroundTuHist + " rounded-[16px] hidden md:block"}>
                            <a href="/userlist" className="flex items-center py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1666 16.9865H18.3333V15.3199C18.3333 13.9392 17.214 12.8199 15.8333 12.8199C15.037 12.8199 14.3276 13.1922 13.8698 13.7723M14.1666 16.9865H5.83329M14.1666 16.9865V15.3199C14.1666 14.773 14.0613 14.2507 13.8698 13.7723M5.83329 16.9865H1.66663V15.3199C1.66663 13.9392 2.78591 12.8199 4.16663 12.8199C4.96297 12.8199 5.67234 13.1922 6.13016 13.7723M5.83329 16.9865V15.3199C5.83329 14.773 5.93865 14.2507 6.13016 13.7723M6.13016 13.7723C6.74454 12.2374 8.24564 11.1532 9.99996 11.1532C11.7543 11.1532 13.2554 12.2374 13.8698 13.7723M12.5 6.1532C12.5 7.53391 11.3807 8.6532 9.99996 8.6532C8.61925 8.6532 7.49996 7.53391 7.49996 6.1532C7.49996 4.77249 8.61925 3.6532 9.99996 3.6532C11.3807 3.6532 12.5 4.77249 12.5 6.1532ZM17.5 8.6532C17.5 9.57367 16.7538 10.3199 15.8333 10.3199C14.9128 10.3199 14.1666 9.57367 14.1666 8.6532C14.1666 7.73272 14.9128 6.98653 15.8333 6.98653C16.7538 6.98653 17.5 7.73272 17.5 8.6532ZM5.83329 8.6532C5.83329 9.57367 5.0871 10.3199 4.16663 10.3199C3.24615 10.3199 2.49996 9.57367 2.49996 8.6532C2.49996 7.73272 3.24615 6.98653 4.16663 6.98653C5.0871 6.98653 5.83329 7.73272 5.83329 8.6532Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span className="flex-1 ml-3 whitespace-nowrap">User</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='flex space-y-2 md:block' >
                    <a href="/logout" className="flex items-center py-3 px-6 text-base font-normal text-white-900 rounded-[16px] hover:bg-[#4C4DDC] dark:text-white hover:bg-gray-100 dark:hover:bg-[#4C4DDC]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.1667 13.3333L17.5 9.99999M17.5 9.99999L14.1667 6.66666M17.5 9.99999L5.83333 9.99999M10.8333 13.3333V14.1667C10.8333 15.5474 9.71405 16.6667 8.33333 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V5.83333C2.5 4.45262 3.61929 3.33333 5 3.33333H8.33333C9.71405 3.33333 10.8333 4.45262 10.8333 5.83333V6.66666" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>


                        <span className="flex-1 ml-3 whitespace-nowrap">Log Out</span>
                    </a>
                </div>


            </div>
        </aside>
    );
}

export default Sidebar;