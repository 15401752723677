import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Sidebar from "../../Sidebar";
import Signature from "../../../../helpers/Signature";
import CryptoJS from "crypto-js";
import { Validator } from "../../../../helpers/Validator";

const UserList = () => {
    const filterByUser = [
        {
            id: "ACTIVE",
            text: "Active",
            isSelected: false
        },
        {
            id: "LOCKED",
            text: "Locked",
            isSelected: false
        },
        {
            id: "SUSPENDED",
            text: "Suspended",
            isSelected: false
        },
        {
            id: "FREEZE",
            text: "Freeze",
            isSelected: false
        },
        {
            id: "BLOCKED",
            text: "Blocked",
            isSelected: false
        },
    ];
    const filterByKYC = [
        {
            id: "KYC NEEDED",
            text: "KYC Needed",
            isSelected: false
        },
        {
            id: "NEED REVIEW",
            text: "Need Review",
            isSelected: false
        },
        {
            id: "APPROVE",
            text: "Approve",
            isSelected: false
        },
        {
            id: "REJECT",
            text: "Reject",
            isSelected: false
        },
        {
            id: "BLOCKED",
            text: "Blocked",
            isSelected: false
        }
    ];
    //element
    const [filterStatusUser] = useState(filterByUser);
    const [filterStatusKYC] = useState(filterByKYC);

    const [listUser, setListUser] = useState([]);
    const [page, setPage] = useState(0);
    const [totalList] = useState(10);
    const [reachMaxList, setReachMaxList] = useState(false);

    const [userStatusId, setUserStatusId] = useState('');
    const [KYCStatusId, setKYCStatusId] = useState('')

    const [searchValue, setSearchValue] = useState('')

    const inputSearch = useRef('');

    const navigate = useNavigate();

    const loadPaginationData = async () => {

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        var valueSearch;

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }

        if (inputSearch.current.value === '') {
            valueSearch = '';
        }
        else {
            valueSearch = searchValue;
        }
        console.log('page', page)
        console.log('totalList', totalList)
        console.log('userStatusId', userStatusId)
        console.log('KYCStatusId', KYCStatusId)

        let url = '/api/user/v01/list?page=' + page + '&limit=' + totalList + '&user_status=' + userStatusId + '&search=' + valueSearch + '&kyc_status=' + KYCStatusId
        console.log('URL', url)
        try{
            let response = await axios.get(
                process.env.REACT_APP_APIHOST + url,
                { headers: dataHeaders })
    
            console.log("loadPaginationData", response)
            if (response.data.data.length === 0) {
                console.log('reachMAX')
                setReachMaxList(true)
            }
            else {
                setListUser((listUser) => [...listUser, ...response.data.data])
            }
        }catch(err)
        {
            console.log("ERROR-1",err)
            console.log("ERROR-2",err.response)
            if(err.response.status === 403){
                navigate('/logout',{ userStatus : 
                {
                    tokenIsExpired : true
                }})
            }
        }
       
    }
    const loadUserList = async () => {

        let getUUID = localStorage.getItem('X3V1aWRfZGV2ZWxvcGVy');
        let decrypt = CryptoJS.AES.decrypt(getUUID, 'salt').toString(CryptoJS.enc.Utf8);
        let resultUUID = JSON.parse(atob(decrypt));
        let payloadSignature = [];
        let signature = Signature(payloadSignature);
        var valueSearch;

        let dataHeaders = {
            'gtoken': 'PASSS',
            'userindex': resultUUID.userindex,
            'tokenlogin': resultUUID.token,
            'signature': signature
        }
        if (inputSearch.current.value === '') {
            valueSearch = '';
        }
        else {
            valueSearch = searchValue;
        }
        // console.log('page', page)
        // console.log('totalList', totalList)
        // console.log('userStatusId', userStatusId)
        // console.log('KYCStatusId', KYCStatusId)

        let url = '/api/user/v01/list?page=' + 0 + '&limit=' + totalList + '&user_status=' + userStatusId + '&search=' + valueSearch + '&kyc_status=' + KYCStatusId
        console.log('URL', url)
        try{
            let response = await axios.get(
                process.env.REACT_APP_APIHOST + url,
                { headers: dataHeaders })
    
            console.log("RESPONSE FILTER", response)
            setListUser(response.data.data);
            setPage(0);
            setReachMaxList(false);
        }
        catch(err){
            console.log('err1', err)
            console.log('err2', err.response)
            if(err.response.status === 403){
                navigate('/logout',{ state : 
                {
                    tokenIsExpired : true
                }})
            }
        }

    }
    const handleScroll = (e) => {
        console.log('masuk')
        // console.log(e)
        const target = e.target;
        const scrollTop = Math.round(target.scrollTop)
        console.log("scrollHeight", target.scrollHeight)
        console.log("scrollTop", scrollTop)
        console.log("clientHeight", target.clientHeight)
        if (target.scrollHeight - scrollTop === target.clientHeight) {
            // console.log("scrollHeight", target.scrollHeight)
            // console.log("scrollTop", target.scrollTop)
            // console.log("clientHeight", target.clientHeight)
            console.log('masuk2')
            if (!reachMaxList) {
                setPage(page + 1)
            }
        }
    }
    const SearchData = (e) => {
        if (e.key === "Enter") {
            setSearchValue(inputSearch.current.value)
        }
    }
    const updateColor = () => {
        filterStatusUser.forEach((item) => {
            if (item.id === userStatusId) {
                item.isSelected = true;
            }
            else {
                item.isSelected = false;
            }
        })

    }
    const updateKYCColor = () => {
        filterStatusKYC.forEach((item) => {
            if (item.id === KYCStatusId) {
                item.isSelected = true;
            }
            else {
                item.isSelected = false;
            }
        })
    }
    const filterByStatus = (id, index) => {
        setUserStatusId(id);
    }
    const filterByKYCStatus = (id, index) => {
        setKYCStatusId(id);
    }
    const openDetailUser = (id) => {
        console.log('ID', id)
        navigate('/userlist/detailUser/' + id)

    }

    useEffect(() => {
        console.log('page', page)
        if (page !== 0) {
            if (!reachMaxList) {
                console.log('inside')
                loadPaginationData(page);
            }
        }
    }, [page]);

    useEffect(() => {
        window.scrollTo(0, 0);
        updateColor();
        updateKYCColor();
        loadUserList();
    }, [userStatusId, KYCStatusId, searchValue]);

    return (
        <>
            <div className="w-full min-h-screen p-4 bg-pure-black">

                <Sidebar />

                <div className="w-full  min-h-full pl-[275px]">
                    <div className="w-full h-full flex flex-col">

                        <div className="w-full  max-w-[1500px] m-auto flex flex-col">
                            <div className="w-full">
                                <div className="mt-[41px]">
                                    <div className="text-white pl-3">
                                        <div className="text-[30px]">User</div>
                                        <div className="text-[#BABEC1]">../User/</div>
                                    </div>
                                    <div className="flex flex-row gap-6">
                                        <div className=" w-[80%] mt-5">
                                            <div className="relative overflow-x-auto overflow-y-auto h-[700px] pr-3" onScroll={(e) => handleScroll(e)}>
                                                <table className="w-full text-sm text-left">
                                                    <thead className="text-xs text-[14px] ">
                                                        <tr className="text-white-primary bg-black-toGray">
                                                            <th scope="col" className="py-3 pl-3 rounded-l-lg ">
                                                                Name
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                <div className="text-center">
                                                                    Account Status
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                <div className="text-center">
                                                                    Join Date
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                <div className="text-center">
                                                                    Last Login
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3">
                                                                <div className="text-center">
                                                                    Status KYC
                                                                </div>
                                                            </th>
                                                            <th scope="col" className="py-3 pl-3 rounded-r-lg ">
                                                                <div className="text-center">
                                                                    Action
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listUser.map((result, key) => (
                                                            <tr className="border-b-2 border-[#3D3F40] text-white-primary hover:bg-slate-400 cursor-pointer" key={key} >
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {result.userfullname}
                                                                </td>
                                                                <td className="py-6 pl-3 font-semibold capitalize">
                                                                    {
                                                                        (result.userstatusdescription).toLowerCase() === 'active' ?
                                                                            <div className="flex justify-center rounded-[8px] py-2 bg-white-primary w-full text-medium-gray">
                                                                                {(result.userstatusdescription).toLowerCase()}
                                                                            </div> :
                                                                            (result.userstatusdescription).toLowerCase() === 'blocked' ?
                                                                                <div className="flex justify-center rounded-[8px] py-2 bg-medium-gray w-full">
                                                                                    {(result.userstatusdescription).toLowerCase()}
                                                                                </div> :
                                                                                (result.userstatusdescription).toLowerCase() === 'suspended' ?
                                                                                    <div className="flex justify-center rounded-[8px] py-2 bg-light-orange w-full text-medium-gray">
                                                                                        {(result.userstatusdescription).toLowerCase()}
                                                                                    </div> :
                                                                                    (result.userstatusdescription).toLowerCase() === 'locked' ?
                                                                                        <div className="flex justify-center rounded-[8px] py-2 bg-red-primary w-full">
                                                                                            {(result.userstatusdescription).toLowerCase()}
                                                                                        </div> :
                                                                                        (result.userstatusdescription).toLowerCase() === 'freeze' ?
                                                                                            <div className="flex justify-center rounded-[8px] py-2 bg-light-blue-white w-full text-medium-gray">
                                                                                                {(result.userstatusdescription).toLowerCase()}
                                                                                            </div> : null
                                                                    }
                                                                </td>
                                                                <td className="py-4 pl-3 font-semibold text-center">
                                                                    {Validator.modifyDate(result.userjointdate)}
                                                                </td>
                                                                <td className="py-4 pl-3 font-semibold text-center">
                                                                    {Validator.modifyDate(result.userlastlogin)}
                                                                </td>
                                                                <td className="py-4 pl-3 font-semibold text-center capitalize">
                                                                    {(result.userkycdescription).toLowerCase()}
                                                                </td>
                                                                <td className="py-4 pl-3 font-semibold">
                                                                    <button onClick={() => openDetailUser(result.userindex)}>
                                                                        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect x="0.166656" width="40" height="40" rx="8" fill="#0028FC" />
                                                                            <path d="M17.6666 20C17.6666 21.3807 18.7859 22.5 20.1666 22.5C21.5474 22.5 22.6666 21.3807 22.6666 20C22.6666 18.6193 21.5474 17.5 20.1666 17.5C18.7859 17.5 17.6666 18.6193 17.6666 20Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M28.1181 20C27.0562 16.6191 23.8976 14.1667 20.1663 14.1667C16.4349 14.1667 13.2763 16.6191 12.2144 20.0001C13.2763 23.381 16.4349 25.8334 20.1663 25.8334C23.8976 25.8334 27.0562 23.3809 28.1181 20Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="flex flex-col min-h-full w-[20%] gap-6">
                                            <div className="block">
                                                <span className="text-[14px] text-white-primary mb-3">Search</span>
                                                <div className="flex flex-row  bg-black-toGray rounded-[4px] px-2 py-2 gap-2 mt-3">

                                                    <input type="text" className="h-[70%] w-full bg-black-toGray rounded-[4px] text-white-primary pl-2 pr-2" onKeyDown={(e) => SearchData(e)} ref={inputSearch}></input>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.5 16.5L6.5 11.5M4.83333 7.33333C4.83333 10.555 7.44501 13.1667 10.6667 13.1667C13.8883 13.1667 16.5 10.555 16.5 7.33333C16.5 4.11167 13.8883 1.5 10.6667 1.5C7.44501 1.5 4.83333 4.11167 4.83333 7.33333Z" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>

                                                </div>
                                            </div>

                                            <div className="flex flex-col h-[100%] gap-10 mt-3">
                                                <div className="flex flex-col h-[45%]">
                                                    <span className="text-[14px] text-white-primary">Filter By Account Status</span>
                                                    {
                                                        filterStatusUser.map((item, key) => {
                                                            return (
                                                                item.isSelected === false ?
                                                                    <button key={key} onClick={() => { filterByStatus(item.id, key) }} className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">{item.text}</button>
                                                                    :
                                                                    <button key={key} onClick={() => { filterByStatus(item.id, key) }} className="h-[25%] w-full bg-blue-cst rounded-[4px] text-white-primary text-center text-[14px] mt-3">{item.text}</button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="flex flex-col h-[45%]">
                                                    <span className="text-[14px] text-white-primary">Filter By KYC Status</span>
                                                    {
                                                        filterStatusKYC.map((item, key) => {
                                                            return (
                                                                item.isSelected === false ?
                                                                    <button key={key} onClick={() => { filterByKYCStatus(item.id, key) }} className="h-[25%] w-full bg-black-toGray rounded-[4px] text-white-primary text-center text-[14px] mt-3">{item.text}</button>
                                                                    :
                                                                    <button key={key} onClick={() => { filterByKYCStatus(item.id, key) }} className="h-[25%] w-full bg-blue-cst rounded-[4px] text-white-primary text-center text-[14px] mt-3">{item.text}</button>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default UserList;