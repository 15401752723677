import { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { tokenIsExpired } = state;

    useEffect(() => {
        if (typeof tokenIsExpired !== "undefined") {
            localStorage.clear();
            navigate('/', { state: { tokenIsExpired: true } });
        }
        else {
            localStorage.clear();
            navigate('/');
        }
    }, [navigate])
    return (<div>Loading ...</div>)
}

export default Logout;